import React from 'react';

import {
    FormFieldNames as ContactFieldNames,
    FORM_NAME as CONTACT_FORM_NAME,
} from '../constants/forms/contact-form';
import {
    FormFieldNames as InquiryFieldNames,
    FORM_NAME as INQUIRY_FORM_NAME,
} from '../constants/forms/inquiry-form';

const Form = () => {
    const renderForm = (name, formFields) => (
        <form
            data-netlify="true"
            netlify-honeypot={ContactFieldNames.BOT}
            method="post"
            name={name}
        >
            <input type="hidden" name="form-name" value={name} />
            <div style={{ display: 'none' }}>
                {
                    Object.keys(formFields).map(fieldKey => (
                        <input key={fieldKey} type="text" name={formFields[fieldKey]} />
                    ))
                }
            </div>
        </form>
    );

    return (
        <>
            {renderForm(CONTACT_FORM_NAME, ContactFieldNames)}
            {renderForm(INQUIRY_FORM_NAME, InquiryFieldNames)}
        </>
    );
}; 

export default Form;
